import { Link, withRouter } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

import NavbarMainCategoryItem from './NavbarMainCategory';
import NavbarFooter from './NavbarFooter';
import Drawer from '@mui/material/Drawer';
import dotetimerLogo from 'img/dotetimer.png';
import { NavbarHeaderWrapper, NavbarLink, NavBarRootList } from './style';
import { NAV_CATEGORY_DATA, NAV_LINK_DATA } from './constant';
import { adminUserVar } from 'stores/adminUser';

const Navbar = () => {
  const { role } = useReactiveVar(adminUserVar);
  const accessibleCategory =
    role.isAdmin && !role.useSpecificPermission
      ? NAV_CATEGORY_DATA
      : NAV_CATEGORY_DATA.filter((item) => {
          for (const { url } of role.pagePermissions) {
            if (item.sub.findIndex((item) => item.link === url) >= 0) {
              return true;
            }
          }
          return false;
        });

  return (
    <>
      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '250px',
            height: 'calc(100% - 45px)',
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <NavbarHeaderWrapper>
          <Link to="/admin">
            <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
              Doteteams
            </Typography>
          </Link>
        </NavbarHeaderWrapper>
        <Divider />
        <Box sx={{ margin: '10px' }}>
          {NAV_LINK_DATA.map((link) => (
            <NavbarLink key={link.link} href={link.link} target="_blank">
              <img src={dotetimerLogo} />
              <Typography>{link.name}</Typography>
            </NavbarLink>
          ))}
        </Box>
        <Divider />
        <NavBarRootList>
          {accessibleCategory.map((item, i) => {
            return (
              <NavbarMainCategoryItem
                key={i}
                defaultIsOpen={item.name !== '관리'}
                categoryData={item}
              />
            );
          })}
        </NavBarRootList>
        <NavbarFooter />
      </Drawer>
    </>
  );
};

export default withRouter(Navbar);
