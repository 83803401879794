import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import dotenv from 'dotenv';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import cache from './stores/cache';
import { getToken } from './utils/JWT';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
dotenv.config();

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    adminUser: AdminUser!
  }
`;
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: getToken() ? `Bearer ${getToken()}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  typeDefs,
});

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'black',
        },
        outlined: {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Pretendard-Regular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiLink: { defaultProps: { underline: 'none' } },
    MuiFormControlLabel: {
      styleOverrides: {
        root: { color: 'black' },
      },
    },
  },
  typography: {
    fontFamily: 'Pretendard-Regular',
    button: {
      textTransform: 'none',
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </ThemeProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
