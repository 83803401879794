import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IMainNavData } from './interface';
import { NavBarChildList } from './style';
import { AdminPageRestrictComponent } from 'components/admin/common/AdminRestrictComponent';

interface INavbarMainCategoryProps {
  defaultIsOpen: boolean;
  categoryData: IMainNavData;
}

const NavbarMainCategoryItem = ({
  defaultIsOpen,
  categoryData,
}: INavbarMainCategoryProps) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  return (
    <>
      <ListItem
        button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        sx={{
          '& .MuiListItemIcon-root > .MuiSvgIcon-root': {
            fontSize: 24,
            color: '#007FFF',
          },
          '& .MuiSvgIcon-root': {
            fontSize: 20,
            color: 'black',
          },
        }}
      >
        <ListItemIcon>{categoryData.icon}</ListItemIcon>
        <ListItemText primary={categoryData.name} />
        {isOpen ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <NavBarChildList component="ul" disablePadding>
          {categoryData.sub.map((item, i) => (
            <AdminPageRestrictComponent
              key={i}
              path={item.link}
              component={
                <ListItemButton
                  onClick={() => {
                    history.push(item.link);
                  }}
                >
                  <ListItemText primary={item.name} />
                </ListItemButton>
              }
            />
          ))}
        </NavBarChildList>
      </Collapse>
    </>
  );
};

export default NavbarMainCategoryItem;
