import { Box, Link, List, styled } from '@mui/material';

export const NavbarHeaderWrapper = styled(Box)<{
  component?: React.ElementType;
}>({
  display: 'flex',
  minHeight: 64,
  alignItems: 'center',
  justifyContent: 'center',
  '& a': { textDecoration: 'none' },
  '& a:visited': { color: 'black' },
});

export const NavbarLink = styled(Link)<{
  component?: React.ElementType;
}>({
  display: 'flex',
  borderRadius: '10px',
  border: '1px solid #E5E8EC',
  height: '52px',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'black',
  marginBottom: '4px',
  '& img': { height: '40px', marginRight: '8px' },
});

export const NavBarRootList = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 12,
    fontSize: 16,
    borderRadius: 4,
    backgroundColor: '#F0F7FF',
  },
  '& .MuiTypography-root': {
    fontWeight: 'bold',
  },
});

export const NavBarChildList = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 52,
    paddingRight: 24,
    paddingTop: 4,
    paddingBottom: 4,

    fontSize: 14,
  },
  '& .MuiTypography-root': {
    fontSize: 14,
  },
});
