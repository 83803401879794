import { gql } from 'graphql-tag';

export const GET_ADMIN_USER = gql`
  query getAdminUser {
    BoardUserGetInfo {
      accountId
      name
      role {
        id
        name
        isDefault
        isAdmin
        useSpecificPermission
        apiPermissions {
          id
          name
          apiType
          allowRead
          allowWrite
          allowDelete
        }
        pagePermissions {
          id
          name
          url
        }
      }
    }
    adminUser @client
  }
`;
