import { useReactiveVar } from '@apollo/client';
import { adminUserVar } from 'stores/adminUser';
import { IPagePermission } from '../permission/interface';

interface IAdminPageRestrictProps {
  path: string;
  component: React.ReactNode;
  isOnlyRootAdmin?: boolean;
}

export const checkPageAccessPermission = (
  page: string,
  pagePermissions: IPagePermission[],
) => {
  if (pagePermissions === null) {
    return false;
  }

  const result = pagePermissions.findIndex((item) => page === item.url);
  return result >= 0;
};

export const AdminPageRestrictComponent = ({
  path,
  component,
  isOnlyRootAdmin,
}: IAdminPageRestrictProps) => {
  const { role } = useReactiveVar(adminUserVar);

  // 최고 관리자면 그냥 패스
  if (role.isAdmin && !role.useSpecificPermission) {
    return <>{component}</>;
  }

  if (isOnlyRootAdmin) {
    return <></>;
  }

  if (checkPageAccessPermission(path, role.pagePermissions)) {
    return <>{component}</>;
  } else {
    return <></>;
  }
};
