import { makeVar } from '@apollo/client';
import {
  IAPIPermission,
  IPagePermission,
} from 'components/admin/permission/interface';
import { getToken } from '../utils/JWT';

export interface IPermissionState {
  isAdmin: boolean;
  name: string | null;
  useSpecificPermission: boolean;
  apiPermissions: Array<IAPIPermission>;
  pagePermissions: Array<IPagePermission>;
}

export interface IAdminUser {
  accountId: string;
  name: string;
  role: IPermissionState;
}

export const isLoggedInVar = makeVar<boolean>(!!getToken());
export const adminUserVar = makeVar<IAdminUser>({
  accountId: '',
  name: '',
  role: {
    isAdmin: false,
    name: '',
    useSpecificPermission: true,
    apiPermissions: [],
    pagePermissions: [],
  },
});
