import { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';

import Navbar from 'components/navbar/Navbar';
import { useQuery } from '@apollo/client';
import { GET_ADMIN_USER } from 'components/navbar/gql';
import { adminUserVar } from 'stores/adminUser';
interface props extends RouteComponentProps {
  children: ReactNode;
}
const AdminTemplate = ({ children }: props) => {
  useQuery(GET_ADMIN_USER, {
    onCompleted: (data) => {
      adminUserVar(data.BoardUserGetInfo);
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
      }}
    >
      <CssBaseline />
      <Navbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 2,
          paddingLeft: 3,
          paddingRight: 3,
          marginLeft: 0,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default withRouter(AdminTemplate);
