import { InMemoryCache } from '@apollo/client';
import { adminUserVar, isLoggedInVar } from './adminUser';
import reactiveMailTemplateList from './mailTemplateList';

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        adminUser: {
          read() {
            return adminUserVar();
          },
        },
        mailTemplateList: {
          read() {
            return reactiveMailTemplateList();
          },
        },
      },
    },
  },
});

export default cache;
