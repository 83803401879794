import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { IMainNavData, INavData } from './interface';

export const NAV_CATEGORY_DATA: IMainNavData[] = [
  {
    name: '데이터',
    icon: <BarChartIcon />,
    sub: [
      { name: '회원 목록', link: '/admin/users' },
      { name: 'D-day(장기) 목록', link: '/admin/goals' },
      { name: 'Todo(단기) 목록', link: '/admin/todos' },
      { name: '활동 내역', link: '/admin/coinLogs' },
      { name: '기부 내역', link: '/admin/donate' },
      { name: '기부 랭킹', link: '/admin/ranking' },
      // { name: '구독 정보 확인', link: '/admin/subscription' },
      { name: '하루세줄', link: '/admin/wbn' },
      { name: '그룹', link: '/admin/group' },
      { name: '그룹 게시글', link: '/admin/groupPost' },
      { name: '그룹 게시글 이미지', link: '/admin/groupPostImage' },
      { name: '프리미엄 로그', link: '/admin/premiumLog' },
      { name: '쿠폰', link: '/admin/coupon' },
    ],
  },
  {
    name: '통계',
    icon: <ShowChartIcon />,
    sub: [
      { name: '회원 통계', link: '/admin/users/stats' },
      { name: '활동 통계', link: '/admin/coinLogs/stats' },
      { name: '기부 통계', link: '/admin/donate/stats' },
      { name: '하루세줄 통계', link: '/admin/wbn/stats' },
      { name: '그룹 통계', link: '/admin/group/stats' },
      { name: '그룹 게시글 통계', link: '/admin/groupPost/stats' },
      { name: '그룹 게시글 이미지 통계', link: '/admin/groupPostImage/stats' },
      { name: '유료 전환율 통계', link: '/admin/conversionRate/stats' },
      { name: '프리미엄 로그 통계', link: '/admin/premiumLog/stats' },
    ],
  },
  {
    name: '관리',
    icon: <ManageAccountsIcon />,
    sub: [
      { name: '배너 이미지 관리', link: '/admin/banner' },
      { name: '메일 로그 관리', link: '/admin/mailLogs' },
      { name: '메일 템플릿 관리', link: '/admin/mailTemplate' },
      { name: '접근 권한 관리', link: '/admin/role' },
      { name: '세부 권한 관리', link: '/admin/permission' },
      { name: '회원 관리', link: '/admin/board-user' },
      { name: '관리자 관리', link: '/admin/board-user/manager' },
    ],
  },
];

export const NAV_LINK_DATA: INavData[] = [
  {
    name: 'dotetimer.com',
    link: 'http://www.dotetimer.com',
  },
];

// export const linkCategories: IMainNavData[] = [
//   {
//     name: '국문 링크',
//     link: '',
//     sub: [
//       { name: '일반 홈페이지', link: 'http://dotetimer.com/?page_id=4251' },
//       { name: '학생용 홈페이지', link: 'http://dotetimer.com/?page_id=3045' },
//       { name: '기부 현황', link: 'http://dotetimer.com/?page_id=2919' },
//     ],
//   },
//   {
//     name: '영문 링크',
//     link: '/stat/user-stat',
//     sub: [
//       { name: '일반 홈페이지', link: 'http://dotetimer.com/?page_id=1554' },
//       { name: '학생용 홈페이지', link: 'http://dotetimer.com/?page_id=3412' },
//       { name: '기부 현황', link: 'http://dotetimer.com/?page_id=2906' },
//     ],
//   },
//   {
//     name: '대만 링크',
//     link: '/admin/manage',
//     sub: [
//       { name: '일반 홈페이지', link: 'http://dotetimer.com/?page_id=2805' },
//     ],
//   },
// ];
