import { Grid, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useReactiveVar } from '@apollo/client';
import { adminUserVar } from '../../stores/adminUser';
import useAuth from '../../hooks/login/useAuth';

const useNavbarFooterStyle = makeStyles(() =>
  createStyles({
    footerWrapper: {
      position: 'fixed',
      width: '250px',
      bottom: 0,
      backgroundColor: 'white',
      color: 'black',
      boxShadow: '0px -5px 5px rgba(0, 0, 0, .1)',
      padding: '5px 16px',
    },
    link: {},
    divider: { margin: '10px 0px', width: '100%' },
    name: {
      fontSize: '18px',
      textAlign: 'center',
      alignSelf: 'center',
      fontWeight: 'bold',
    },
    logoutBtn: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
  }),
);

const NavbarFooter = () => {
  const classes = useNavbarFooterStyle();
  const { logout } = useAuth();
  const adminUser = useReactiveVar(adminUserVar);

  return (
    <Grid
      container
      justifyContent="space-around"
      className={classes.footerWrapper}
    >
      <Grid className={classes.name} item>
        {adminUser.name} 님
      </Grid>
      <Grid item>
        <Button className={classes.logoutBtn} onClick={logout}>
          로그아웃
        </Button>
      </Grid>
    </Grid>
  );
};

export default NavbarFooter;
