import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { adminUserVar, isLoggedInVar } from '../../stores/adminUser';
import { removeToken, setToken } from '../../utils/JWT';

const BOARD_USER_REGISTER = gql`
  mutation boardUserRegister(
    $accountId: String!
    $password: String!
    $name: String!
  ) {
    BoardUserRegister(accountId: $accountId, password: $password, name: $name) {
      accountId
      name
      token
    }
  }
`;

const LOG_IN = gql`
  mutation boardUserLogin($accountId: String!, $password: String!) {
    BoardUserLogin(accountId: $accountId, password: $password) {
      accountId
      name
      token
    }
  }
`;

export default function useAuth() {
  const client = useApolloClient();
  const history = useHistory();

  const [registerRequest] = useMutation(BOARD_USER_REGISTER, {
    onCompleted: (data) => {
      setToken(data.BoardUserRegister.token);
      isLoggedInVar(true);

      history.push('/biz');
    },
    onError: (e) => {
      console.dir(e);
      alert('login error');
    },
  });

  const [loginRequest] = useMutation(LOG_IN, {
    onCompleted: (data) => {
      setToken(data.BoardUserLogin.token);
      isLoggedInVar(true);

      history.push('/biz');
    },
    onError: (e) => {
      console.dir(e);
      alert('login error');
    },
  });

  const logout = () => {
    client.cache.evict({ fieldName: 'isLoggedIn' });
    client.cache.evict({ fieldName: 'adminUser' });
    client.cache.gc();
    removeToken();
    isLoggedInVar(false);
    adminUserVar({
      name: '',
      accountId: '',
      role: {
        isAdmin: false,
        name: '',
        useSpecificPermission: true,
        apiPermissions: [],
        pagePermissions: [],
      },
    });
    history.push('/login');
  };

  return { register: registerRequest, login: loginRequest, logout };
}
